<template>
  <div class="row">
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Adı Soyadı</div>
      <div class="font-size-lg font-weight-bold">
        {{ selectedItem.fullName }}
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Email</div>
      <div class="font-size-lg font-weight-bold">
        {{ selectedItem.email }}
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Telefon</div>
      <div class="font-size-lg font-weight-bold">
        {{ selectedItem.phone ?? '-' }}
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Durum</div>
      <div class="font-size-lg font-weight-bold">
        <div
          v-html="$customFunctions.getIsActiveBadge(selectedItem.isActive)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailView',
  props: {
    selectedItem: String | Object,
  },
};
</script>
